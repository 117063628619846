import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { MDXLayout as PageLayout } from "../components/blocks/mdx-layout";
import { SEO } from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Legal Notice" noIndex mdxType="SEO" />

    <h1 {...{
      "id": "legal-notice"
    }}>{`Legal Notice`}</h1>
    <h2 {...{
      "id": "information-pursuant-to-sect-5-german-telemedia-act-tmg"
    }}>{`Information pursuant to Sect. 5 German Telemedia Act (TMG)`}</h2>
    <p>{`Lennart J`}{`ö`}{`rgens`}<br />{`
Pfarrer-S`}{`ö`}{`ller-Platz 13`}<br />{`
63839 Kleinwallstadt`}</p>
    <h2 {...{
      "id": "contact"
    }}>{`Contact`}</h2>
    <p>{`E-mail: `}<a parentName="p" {...{
        "href": "mailto:hello@lekoarts.de"
      }}>{`hello@lekoarts.de`}</a></p>
    <h2 {...{
      "id": "person-responsible-for-editorial"
    }}>{`Person responsible for editorial`}</h2>
    <p>{`Lennart J`}{`ö`}{`rgens`}<br />{`
Pfarrer-S`}{`ö`}{`ller-Platz 13`}<br />{`
63839 Kleinwallstadt`}</p>
    <h2 {...{
      "id": "dispute-resolution-proceedings-in-front-of-a-consumer-arbitration-board"
    }}>{`Dispute resolution proceedings in front of a consumer arbitration board`}</h2>
    <p>{`We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      